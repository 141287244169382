import * as React from "react";
import CaseHeroImage from "./caseHeroImage";

const CasePreviewDescription = ({ title, description, buttonText, buttonLink }) => {
    return (
        <div className="case-preview__description">
            {title && <h2 className="case-preview__title">{title}</h2>}
            {description && <p>{description}</p>}
            {buttonText && buttonLink && <a className="bordered-button" href={buttonLink}>{buttonText}</a>}
        </div>
    )
}

const CasePreviewImage = ({ image, imageALT="" }) => {
    return (
        <div className="case-preview__cover">
            <CaseHeroImage image={image} imageALT={imageALT}/>
        </div>
    )
}

const CasePreview = ({title, description, buttonText, buttonLink, image, imageSide = "right"}) => {

    return (
        <div className="case-preview">
            {imageSide === "left" && <CasePreviewImage image={image} imageALT={title}/>}

            <CasePreviewDescription
                title={title}
                description={description}
                buttonText={buttonText}
                buttonLink={buttonLink} />

            {imageSide === "right" && <CasePreviewImage image={image} imageALT={title}/>}
        </div>
    )
}

export default CasePreview;
