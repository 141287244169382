import * as React from "react";
import scrollTo from "gatsby-plugin-smoothscroll";
import { Helmet } from "react-helmet";
import { withPrefix, Link } from "gatsby";

import VideoBg from "../components/videoBg";
import TextArrows from "../components/textArrows";

import ArrowBlank from "../svg/arrow_blank.svg";
import SliderNext from "../svg/slider-next.svg";
import SliderPrev from "../svg/slider-prev.svg";
import BottomLeftCorner from "../svg/bottom_left_corner.svg";
import BottomRightCorner from "../svg/bottom_right_corner.svg";
import Circle from "../svg/circle.svg";
import MainSectionLogo from "../svg/main_section_logo.svg";
import Scroller from "../svg/scroller.svg";
import TopLeftCorner from "../svg/top_left_corner.svg";
import TopRightCorner from "../svg/top_right_corner.svg";
import { FRONT_DOMAIN } from "../config";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';

import "../scss/main.scss";
import 'swiper/css';
import PageHeader from "../components/pageHeader";
import CasePreview from "../components/casePreview";

// Images:
import BentleyPreviewImage from "../img/cases/bentley/preview.png";
import PrismPreviewImage from "../img/cases/prism/preview.png";
import MQDPreviewImage from "../img/cases/mqd/preview.png";
import EmotivePreviewImage from "../img/cases/emotive/preview.png";
import MusePreviewImage from "../img/cases/muse/preview.png";


const WorksPage = () => {

    return (
        <>
            <Helmet>
                <title>Works | Teleport</title>
                <script src={withPrefix('inobounce.js')}></script>
                <meta property="og:image" content={`${FRONT_DOMAIN}/img/share_default.jpg`}/>
                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
                <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"
                      rel="stylesheet"/>
            </Helmet>

            <VideoBg/>

            <div className="site-section__about-top-background"></div>

            <section id="main-section" className="site-section">
                <PageHeader
                    titleText={"Our selected projects"}
                    subTitleText={"Let Da Vinci, Hokusai, Kahlo, or Michelangelo transform you into a piece of art. We are bringing back these..."}
                />
                <CasePreview
                    title={"BENTLEY BLACK BOX Dark Matter Experience"}
                    description={"A thrilling black box experience unveiling Bentley's latest model"}
                    buttonText={"Read the case"}
                    buttonLink={"/works/bentley"}
                    image={BentleyPreviewImage}
                />

                <CasePreview
                    title={"PRISM: The Art of Light"}
                    description={"An AI-powered journey bringing human emotions into spatial colourful spheres"}
                    buttonText={"Read the case"}
                    buttonLink={"/works/prism"}
                    image={PrismPreviewImage}
                    imageSide="left"
                />

                <CasePreview
                    title={"Mother’s Day Queen: The AI Magic Mirror"}
                    description={"Discover “the queen” inside through the AI-powered interactive photo booths."}
                    buttonText={"Read the case"}
                    buttonLink={"/works/mothers-day-queen"}
                    image={MQDPreviewImage}
                />

                <CasePreview
                    title={"The Emotive Clouds Show"}
                    description={"A dynamic cloud show that can craft new visuals using physical stimulation or heartbeat frequency at Cloudera EVOLVE Conference Singapore 2024"}
                    buttonText={"Read the case"}
                    buttonLink={"/works/emotive-clouds-show"}
                    image={EmotivePreviewImage}
                    imageSide="left"
                />

                <CasePreview
                    title={"Digital Muse"}
                    description={"Bringing back the classic arts to you through \n" +
                        "interactive AI-driven technology"}
                    buttonText={"Read the case"}
                    buttonLink={"/works/digital-muse"}
                    image={MusePreviewImage}
                />

            </section>
        </>
    )
}

export default WorksPage;
